import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "./style.css";



function Loader () {
  return(
    <div className="LoaderWrap">
      <CircularProgress className="loaderBody" />
    </div>
  )
}

export default Loader;