import React, { useState} from "react";
// import userImg from '../../images/user.png';
import ScrollToBottom from 'react-scroll-to-bottom';
import { useSelector, useDispatch } from "react-redux";
import {PanDialog} from '../DetailsDialog';
import './style.css';
import { Button } from "@mui/material";

function UserDetails() {
  // const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.userReducer);
  const {userFace, userPan, userSign, systemAadhaar} = userReducer;
  const [openPanDialog, setOpenPanDialog] = useState(false);
  const [verify, setVerify] = useState(false);
  const [markedSign, setMarkedSign] = useState(false);

  const changeToBlob = async () => {
    const data = await fetch('https://orbostorage1test.blob.core.windows.net/facematchdemo/Screenshot_2022-07-20.png');
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onload = () => {
        const base64data = reader.result;   
        resolve(base64data);
        dispatch({type: "SYSTEM_AADHAAR_CARD", payload: base64data})
      }
    });
  }

  const verifyPan = () => {
    setOpenPanDialog(true);
  }

  const handlePanClose = () => {
    setOpenPanDialog(false);
  }

  const handlePanSubmit = () => {
    setOpenPanDialog(false);
    setVerify(true);
  }

  const signPrimary = () => {
    setMarkedSign(true);
  }

  return(
    <ScrollToBottom>
    <div className="detailsWrapper">
      <div className="detailsHead">User Details</div>
      <div className="detailsInnerWrap">
        <div className="detailsSubhead">System</div>
        <div className="detailsBox">
          <div className="systemDetails">
            <div className="detailsTitle">Tracking ID:</div>
            <div className="detailsText">7373737</div>
          </div>
          <div className="systemDetails">
            <div className="detailsTitle">Permanent Address:</div>
            <div className="detailsText">Tola Chhawni, west champaran, Bihar - 845438</div>
            {/* <div className="detailsText">302, Spring view, Parekh nagar, Mumbai - 400067</div> */}
          </div>
          <div className="systemDetails">
            <div className="detailsTitle">Communication Address:</div>
            <div className="detailsText">RBL Bank Ltd., One Indiabulls centre, Lower Parel, Mumbai - 400013</div>
          </div>
        </div>
    
        <div className="detailsSubhead">System Aadhaar Photo</div>
        <div className="userImgWrapper">
          {/* <img style={{width: 'inherit'}} alt="user" src={systemAadhaar} /> */}
          <img style={{width: 'inherit'}} onLoad={changeToBlob} alt="aadhar"  src={`https://orbostorage1test.blob.core.windows.net/facematchdemo/Screenshot_2022-07-20.png`} />
        </div>
        {userFace ? (<>
        <div className="detailsSubhead">Live Screenshot: User face</div>
        <div className="imgWrapper">
          <img style={{width: 'inherit'}} alt="user" src={userFace} />
        </div>
        </>) 
        : null}
        {userPan ? (<>
          <div className="titleWrapper">
            <div className="detailsSubhead">Live Screenshot: Pan Card </div>
            {verify ? <Button variant="contained" color="success">Verified</Button> : <Button variant="contained" onClick={() => verifyPan()}>Verify</Button>}
          </div>
          <div className="imgWrapper">
            <img style={{width: 'inherit'}} alt="pan" src={userPan} />
          </div>
        </>) 
        : null}
        {userSign ? (<>
          <div className="titleWrapper">
            <div className="detailsSubhead">Live Screenshot: Signature</div>
            {markedSign ? <Button variant="contained" color="success">primary signature</Button> : <Button style={{width: '145px'}} variant="contained" onClick={() => signPrimary()}>Make as primary signature</Button>}
          </div>
          <div className="imgWrapper">
            <img style={{width: 'inherit'}} alt="sign" src={userSign} />
          </div>
        </>) 
        : null}
        
      </div>
      {/* <div ref={messagesEndRef} /> */}
    </div>
    <PanDialog handlePanOpen={openPanDialog} panImage={userPan} handlePanClose={handlePanClose} handlePanSubmit={handlePanSubmit} />
    </ScrollToBottom>
  )
}

export default UserDetails;