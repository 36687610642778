
import React, {useEffect, useState} from "react";
import {
  AgoraVideoPlayer,
  createClient,
  // ClientConfig,
  createMicrophoneAndCameraTracks,
  // IAgoraRTCRemoteUser,
  // IMicrophoneAudioTrack,
  // ICameraVideoTrack
} from "agora-rtc-react";
//   import {
//     ClientConfig,
//     IAgoraRTCRemoteUser,
//     ICameraVideoTrack,
//     IMicrophoneAudioTrack,
//   } from "agora-rtc-sdk-ng";
import ActionButtons from './components/ActionButtons';
import UserDetails from "./components/UserDetails";
import { Button } from "@mui/material";

    const config = { 
        mode: "rtc", codec: "vp8",
    };
    const appId = "041eee725c044fe7ad069314a65599ff"; //ENTER APP ID HERE
    const token= null;
    const useClient = createClient(config);
    const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();



function App() {
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");

  return (
    <div>
      {inCall ? (
        <VideoCall setInCall={setInCall} channelName={channelName} />
      ) : (
        <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
        
      )}
    </div>
  );
}

const VideoCall = ({ setInCall, channelName }) => {

  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  // using the hook to get access to the client object
  const client = useClient();
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(appId, name, token, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);

    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }

    // return () => {
    //   (async () => {
    //   if (tracks) await client.unpublish([tracks[0], tracks[1]]);
    //    await client.leave();
    //   })();
    //  }

  }, [channelName, client, ready, tracks]);
  
  return (
    <div className="App">
      
      {ready && tracks && (
        <>
          <ActionButtons videoTracks={users.map(usr => usr.videoTrack)} />
          <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
        </>
      )}
      {start && tracks && 
      (
        <div className="homeWrapper">
          <Videos users={users} tracks={tracks} />
          <UserDetails />
        </div>
      )}
    </div>
  );
};

const Videos = ({ users, tracks }) => {

  return (
      <div className="videoWrapper" id="videos">
        {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
        <AgoraVideoPlayer className="cameraPos" videoTrack={tracks[1]} />
        {users.length > 0 &&
          
            (users[0].videoTrack) ? 
               (
                <AgoraVideoPlayer style={{height: '640px', width: '480px'}} className='vid' videoTrack={users[0].videoTrack} />
              )
             : null
          }
      </div>
  );
};

export const Controls = ({ tracks, setStart, setInCall }) => {
  const client = useClient();
  const [trackState, setTrackState] = useState({ video: true, audio: true });


  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.unpublish([tracks[0], tracks[1]]);
    await client.leave();
    client.removeAllListeners();
    // we close the tracks to perform cleanup
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    window.location.reload();
  };

  console.log(tracks)
  return (
    <div className="controls">
      <Button variant="contained" className={trackState.audio ? "on" : ""}
        onClick={() => mute("audio")}>
        {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
      </Button>
      <Button variant="contained" className={trackState.video ? "on" : ""}
        onClick={() => mute("video")}>
        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
      </Button>
      {<Button variant="contained" color="error" onClick={() => leaveChannel()}>Leave</Button>}
    </div>
  );
};

const ChannelForm = ({
  setInCall,setChannelName
}) => {
//   const { setInCall, setChannelName } = props;

  return (
    <form className="join">
      {appId === '' && <p style={{color: 'red'}}>Please enter your Agora App ID in App.tsx and refresh the page</p>}
      <input type="text"
        placeholder="Enter Channel Name"
        onChange={(e) => setChannelName(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={(e) => {
        e.preventDefault();
        setInCall(true);
      }}>
        Join
      </Button>
    </form>
  );
};
export default App;
