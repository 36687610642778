import axios from "axios";


    export async function faceMatchService(data) {
  
        try {
        const response = await axios.post(`https://gateway.orbo.ai/api/pictures/facematch/upload?access_token=jUEFb5jsfqJEL8dZRKOW8fNzk9eFwmJpPgdvFKPt63RmRzuPDXbGrDhNj1TRgy9f`, data,
        {headers: {
            'Content-Type': 'multipart/form-data'
        }});
        return response.data;
        } 
        catch(error) {
        // console.log(error.response.data);
        return error.response.data.error;
        }
    }

  export const b64toBlob = (base64) => {
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    // Replace extension according to your media type
    const imageName = date + '.' + text + '.jpeg';
    const imageBlob =  base64.replace(/^data:image\/[a-z]+;base64,/, "");
    const byteString = window.atob(imageBlob);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    const file = new File([blob], imageName, { type: 'image/jpeg' });
    return file;
  }