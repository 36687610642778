import React from "react";
import './style.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailsDialog({handleOpen, handleClose}) {

  const [DOB, setDOB] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [name, setName] = React.useState('');
  const [alignment, setAlignment] = React.useState('');

  const handleDOB = (event, newAlignment) => {
    setDOB(newAlignment);
  };
  const handleAddess = (event, newAlignment) => {
    setAddress(newAlignment);
  };
  const handleName = (event, newAlignment) => {
    setName(newAlignment);
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

    return (
      <div>
      <Dialog
        open={handleOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Verify Details</DialogTitle>
        <DialogContent dividers>
          <div className="dialogContent"> 
            <div className="dialogText">Place of birth</div>
            <div>:Bihar</div>
            <ToggleButtonGroup
              varient="contained"
              color="primary"
              value={DOB}
              exclusive
              onChange={handleDOB}
              style={{marginLeft: '50px'}}
            >
              <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
              <ToggleButton color="success" value="match">Match</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="dialogContent"> 
            <div className="dialogText">Permanent address pin code</div>
            <div>:845438</div>
            <ToggleButtonGroup
              varient="contained"
              color="primary"
              value={address}
              exclusive
              onChange={handleAddess}
              style={{marginLeft: '50px'}}
            >
              <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
              <ToggleButton color="success" value="match">Match</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="dialogContent"> 
            <div className="dialogText">Mother's name</div>
            <div>:Qaiser</div>
            <ToggleButtonGroup
              varient="contained"
              color="primary"
              value={name}
              exclusive
              onChange={handleName}
              style={{marginLeft: '50px'}}
            >
              <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
              <ToggleButton color="success" value="match">Match</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="dialogContent"> 
            <div className="dialogText">Occupation</div>
            <div>:Service</div>
            <ToggleButtonGroup
              varient="contained"
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              style={{marginLeft: '50px'}}
            >
              <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
              <ToggleButton color="success" value="match">Match</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleClose}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
    )
}

function PanDialog({handlePanOpen, handlePanClose, handlePanSubmit, panImage}) {
  const [panNumber, setPanNumber] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const [nsdlName, setNsdlName] = React.useState('');
  const [aadhaarName, setAadhaarName] = React.useState('');
  const [fatherName, setFatherName] = React.useState('');
  const [panName, setPanName] = React.useState('');

  const handlePanNumber = (event, newAlignment) => {
    setPanNumber(newAlignment);
  };
  const handleBirth = (event, newAlignment) => {
    setBirth(newAlignment);
  };
  const handleNsdlName = (event, newAlignment) => {
    setNsdlName(newAlignment);
  };
  const handleAadhaarName = (event, newAlignment) => {
    setAadhaarName(newAlignment);
  };
  const handleFatherName = (event, newAlignment) => {
    setFatherName(newAlignment);
  };
  const handlePanName = (event, newAlignment) => {
    setPanName(newAlignment);
  };

  return(
    <Dialog
      open={handlePanOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePanClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>PAN Details</DialogTitle>
      <DialogContent className="contentBox" dividers>
        <div style={{width: '350px'}}>
          <img style={{width: 'inherit'}} alt="pan" src={panImage} />
        </div>
        <div>
        <div className="dialogContent"> 
          <div className="dialogText">PAN Number</div>
          <div className="dialogText">:AMLPJ3870A</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={panNumber}
            exclusive
            onChange={handlePanNumber}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dialogContent"> 
          <div className="dialogText">Date of birth</div>
          <div className="dialogText">:06/02/1990</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={birth}
            exclusive
            onChange={handleBirth}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dialogContent"> 
          <div className="dialogText">Name as per NSDL</div>
          <div className="dialogText">:MD Danish Jamil</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={nsdlName}
            exclusive
            onChange={handleNsdlName}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dialogContent"> 
          <div className="dialogText">Name as per Aadhaar</div>
          <div className="dialogText">:MD Danish Jamil</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={aadhaarName}
            exclusive
            onChange={handleAadhaarName}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dialogContent"> 
          <div className="dialogText">Father's Name</div>
          <div className="dialogText">:Jamilur Rahman</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={fatherName}
            exclusive
            onChange={handleFatherName}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dialogContent"> 
          <div className="dialogText">Name as per PAN</div>
          <div className="dialogText">:MD Danish Jamil</div>
          <ToggleButtonGroup
            varient="contained"
            color="primary"
            value={panName}
            exclusive
            onChange={handlePanName}
            style={{marginLeft: '50px'}}
          >
            <ToggleButton color="error" value="not-match">Mismatch</ToggleButton>
            <ToggleButton color="success" value="match">Match</ToggleButton>
          </ToggleButtonGroup>
        </div>
        </div>
      </DialogContent>
      <DialogActions>
          <Button variant="outlined" color="error" onClick={handlePanClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handlePanSubmit}>Submit</Button>
        </DialogActions>

    </Dialog>
  )
}

export {DetailsDialog, PanDialog};