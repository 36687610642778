import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/index';
import thunk from "redux-thunk";
import logger from 'redux-logger';

const composedEnhancer = composeWithDevTools(
  // EXAMPLE: Add whatever middleware you actually want to use here
  applyMiddleware(logger, thunk)
  // other store enhancers if any
)

const store = createStore(rootReducer, composedEnhancer)
export default store;
